import {Field} from "formik";
import {FunctionComponent} from "react";

interface RadioButtonProps {
    value: any,
    checked?: boolean | undefined,
    fieldName: string
    setFieldValue?: (field: string, value: any) => void
    onChange?: (value: string) => void | undefined
}

export const RadioButton: FunctionComponent<RadioButtonProps> = ({value, fieldName, checked, onChange , children, setFieldValue}) => (
    <div className={'flex items-center mb-4'}>
        <label htmlFor={`${fieldName}-${value}`} className={'text-black font-bold cursor-pointer block inline-flex gap-2 items-center'}>
            <Field id={`${fieldName}-${value}`}
                   name={fieldName}
                   type='radio'
                   value={value}
                   onChange={(e: any) => {
                       if(onChange){
                           onChange(e.target.value)
                       }
                       if(setFieldValue) {
                           setFieldValue(fieldName, e.target.value);
                       }
                   }}
                   className={'form-radio rounded-xl border-green border-2 focus:ring-offset-0 w-24 h-24 text-green mr-3 align-top focus:ring-0'}
                   checked={checked}
            />
            {children}
        </label>
    </div>
)