import {HeaderOptions} from "../../types/headerOptions";
import {FunctionComponent} from "react";
import {Heading} from "../atoms/basics/heading";
import {Paragraph} from "../atoms/basics/paragraph";

interface props {
    headerTag: HeaderOptions
    headerText: string
    descriptionText: string
}

export const Description: FunctionComponent<props> = ({headerTag, headerText, descriptionText}) => (
    <div className={'mb-6'}>
        <Heading tag={headerTag}>
            {headerText}
        </Heading>
        <Paragraph>
            {descriptionText}
        </Paragraph>
    </div>
)