import {FunctionComponent} from "react";
import {Logo} from "../atoms/logo";
import {OrderProcessBar} from "../molecules/orderProcessBar";

interface props {
    step: number
}

export const Header: FunctionComponent<props> = ({step}) => (
    <>
        <header className={'flex justify-center mt-4'}>
            <a href='https://fruitboeraanhuis.nl/'>
                <Logo/>
            </a>
        </header>
        {step < 4 ?
            <OrderProcessBar step={step}/> : null
        }
    </>
)