export const CheckIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <g id="Component_3_1" data-name="Component 3 – 1">
            <circle id="Ellipse_5" data-name="Ellipse 5" cx="16" cy="16" r="16" fill="#0ead69"/>
        </g>
        <g id="outline-check-small" transform="translate(10.284 11.593)">
            <path id="Path_2" data-name="Path 2" d="M18.725,9l-8.208,8.208L7,13.69" transform="translate(-7 -9)"
                  fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
        </g>
    </svg>
)