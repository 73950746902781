import ScrollContainer from "react-indiana-drag-scroll";
import {FruitBoxCard} from "./fruitBoxCard";
import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {LeftIcon} from "../../../atoms/icons/leftIcon";
import {RightIcon} from "../../../atoms/icons/rightIcon";
import {ProductOutputDto} from "../../../../generated-sources/openapi";

interface FruitBoxSelectorProps {
    value: string,
    onChange: (value: string) => void
    products: Array<ProductOutputDto>
}

export const FruitBoxSelector: FunctionComponent<FruitBoxSelectorProps> = ({value, onChange, products}) => {
    const [firstItem, setFirstItem] = useState(true);
    const [lastItem, setLastItem] = useState(false);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const scrollEl = useRef(null);

    const pressFruitBox = (value: string) => {
        onChange(value);
    }

    useEffect(() => {
        if(value === ''){
            pressFruitBox(products[0].id);
        }

        if (firstLoad && value === 'Fruitbox XXL') {
            if (!lastItem) {
                // @ts-ignore
                const element: HTMLElement = scrollEl.current.getElement();
                console.log(element.scrollWidth)
                const itemWidth = element.scrollWidth < 1152 ? 160 + 16 : 256 + 32
                if (element.scrollWidth - element.clientWidth !== element.scrollLeft) {
                    const slide = Math.round(element.scrollLeft / itemWidth) + 1;
                    element.scrollLeft = (slide) * itemWidth
                }
                setFirstItem(element.scrollLeft == 0)
                setLastItem(element.scrollWidth - element.clientWidth === element.scrollLeft)
                setFirstLoad(false);
            }
        }
    }, [value])

    const scrollEnd = () => {
        if (scrollEl.current) {
            // @ts-ignore
            const element: HTMLElement = scrollEl.current.getElement();
            const itemWidth = element.scrollWidth < 1152 ? 160 + 16 : 256 + 32
            if (element.scrollWidth - element.clientWidth !== element.scrollLeft) {
                const slide = Math.round(element.scrollLeft / itemWidth)
                element.scrollLeft = (slide * itemWidth)
            }
            setFirstItem(element.scrollLeft == 0)
            setLastItem(element.scrollWidth - element.clientWidth === element.scrollLeft)
        }
    }

    const nextCard = () => {
        if (scrollEl.current) {
            if (!lastItem) {
                // @ts-ignore
                const element: HTMLElement = scrollEl.current.getElement();
                const itemWidth = element.scrollWidth < 1152 ? 160 + 16 : 256 + 32
                if (element.scrollWidth - element.clientWidth !== element.scrollLeft) {
                    const slide = Math.round(element.scrollLeft / itemWidth) + 1;
                    element.scrollLeft = (slide * itemWidth)
                }
                setFirstItem(element.scrollLeft == 0)
                setLastItem(element.scrollWidth - element.clientWidth === element.scrollLeft)
            }
        }
    }

    const prevCard = () => {
        if (scrollEl.current) {
            if (!firstItem) {
                // @ts-ignore
                const element: HTMLElement = scrollEl.current.getElement();
                const itemWidth = element.scrollWidth < 1152 ? 160 + 16 : 256 + 32
                const slide = Math.round(element.scrollLeft / itemWidth) - 1;
                element.scrollLeft = slide * itemWidth;
                setFirstItem(element.scrollLeft == 0)
                setLastItem(element.scrollWidth - element.clientWidth === element.scrollLeft)
            }
        }
    }


    return (
        <div className={'cursor-pointer relative mb-8'}>
            <ScrollContainer ref={scrollEl} className={'whitespace-nowrap'} onEndScroll={scrollEnd}
                             style={{scrollBehavior: 'smooth'}}>
                {products.map(product => (
                    <FruitBoxCard key={product.id} imageSrc={product.image} title={product.name} price={product.price.amount.toFixed(2).replace('.',',')} portions={product.portions} active={value === product.id}
                                  pressCard={() => pressFruitBox(product.id)}/>
                ))}
            </ScrollContainer>
            <div
                onClick={prevCard}
                className={`absolute right-16 md:top-0 md:h-full fill-current flex items-center md:-left-16 h-40 w-40 md:w-48
                ${firstItem ? 'text-gray' : 'text-green'}`}>
                <LeftIcon/>
            </div>
            <div
                onClick={nextCard}
                className={`absolute right-4 md:top-0 md:h-full fill-current flex items-center md:-right-16 h-40 w-40 md:w-48 text-green
                ${lastItem ? 'text-gray' : 'text-green'}`}>
                <RightIcon/>
            </div>
        </div>
    )
}