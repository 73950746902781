import {AllProductOutputDto, PriceOutputDtoCalculationMethodEnum} from "../generated-sources/openapi";
import {ShoppingCartItemInterface} from "../types/shoppingCartItemInterface";

type Hook = () => [
    (subscription: string,
     honey: boolean,
     sap: boolean,
     sapCount: number,
     deliveryFrequency: number,
     gift: boolean,
     showFrequency: boolean,
     paymentFrequency?: number,
     shippingPrice?: number) => string,
    (allProducts: AllProductOutputDto, cart: ShoppingCartItemInterface[], deliveryFrequency: number, showFrequency: boolean, paymentFrequency: number, shippingPrice?: number, ebook?: boolean) => string,
    (sapCount: number, price: number, bundlePrice: number, minAmount: number, additionAmount: number, calculationCategory: string) => number
];


export const useCalculatePrice: Hook = () => {
    const calculatePrice = (
        subscription: string,
        honey: boolean,
        sap: boolean,
        sapCount: number,
        deliveryFrequency: number,
        gift: boolean,
        showFrequency: boolean = false,
        paymentFrequency: number = 1,
        shippingPrice: number = 0.0
    ): string => {
        let price = 0.0;

        switch (subscription) {
            case 'S':
                price += 9.99;
                break;
            case 'M':
                price += 14.99;
                break;
            case 'L':
                price += 19.99;
                break;
            case 'XL':
                price += 24.99;
                break;
            case 'XXL':
                price += 29.99;
                break;
        }

        if (honey) price += 1.99;
        if (sap) price += calculateSapPrice(sapCount);
        if (paymentFrequency > 1) price = price * deliveryFrequency;
        if (gift && deliveryFrequency === 1) price += 1.99
        price += shippingPrice

        const priceText = price.toFixed(2).toString().replace('.', ',');

        if (showFrequency) {
            switch (deliveryFrequency) {
                case 1:
                    return `Eenmalig ${priceText}`;
                case 2:
                    return `Twee wekelijks ${priceText}`
                default:
                    return `Wekelijks ${priceText}`
            }
        }

        return priceText;
    }

    const calculateLitrePrice = (
        amount: number,
        startPrice: number,
        bundleAmount: number,
        additionPrice: number
    ): number => {
        let total = 0;

        switch (amount) {
            case 0.5:
                total += startPrice;
                break;
            case 1:
                total += startPrice + 3;
                break;
            case 1.5:
                total += startPrice + 5;
                break;
            case 2:
                total += startPrice + 7;
                break;
            default:
                total += startPrice + 7;
                total += (amount - 2) / 0.5 * additionPrice;
                break;
        }

        return Math.round(total * 100) / 100;
    }

    const calculateShoppingCartPrice = (allProducts: AllProductOutputDto, cart: ShoppingCartItemInterface[], deliveryFrequency: number, showFrequency: boolean = false,  paymentFrequency: number = 1, shippingPrice: number = 0.0, ebook = false) => {
        const products = [...allProducts.gift, ...allProducts.extras, ...allProducts.fruitBoxes];
        let totalPrice = 0;

        if(ebook){
            totalPrice += 2.99;
        }

        cart.forEach(item => {
            const product = (products.filter(value => value.id === item.id))[0];

            if (product.price.bundleAmount === -1) {
                totalPrice += product.price.amount * item.amount;
            } else {
                if(product.price.calculationMethod === PriceOutputDtoCalculationMethodEnum.LitreCalculator){
                    totalPrice += calculateLitrePrice(item.amount, product.price.amount, product.price.bundleAmount, product.price.additionAmount);
                }else {
                    totalPrice += product.price.amount + ((item.amount - product.minAmount) / product.minAmount * product.price.bundleAmount);
                }
            }
        });

        if (paymentFrequency > 1) totalPrice = totalPrice * deliveryFrequency;

        if (paymentFrequency > 1){
            totalPrice += shippingPrice * deliveryFrequency
        }else{
            totalPrice += shippingPrice
        }

        const priceText = totalPrice.toFixed(2).toString().replace('.', ',');

        if (showFrequency) {
            switch (deliveryFrequency) {
                case 1:
                    return `Eenmalig ${priceText}`;
                case 2:
                    return `Twee wekelijks ${priceText}`
                default:
                    return `Wekelijks ${priceText}`
            }
        }

        return priceText;
    }

    const calculateSapPrice = (sapCount: number, price: number = 1.99, bundlePrice: number = 0.5, minAmount: number = 0.5, additionAmount: number = 0.5, calculationCategory: string = 'default') => {
        if(calculationCategory === 'default') {
            return price + ((sapCount - minAmount) / minAmount * bundlePrice);
        }
        return calculateLitrePrice(sapCount, price, bundlePrice, additionAmount);
    }

    return [calculatePrice, calculateShoppingCartPrice, calculateSapPrice]
}