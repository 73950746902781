import {FC} from "react";

interface props {
    onclick: () => void
}

export const InfoIcon: FC<props> = ({onclick}) => (
    <span style={{height: "20px", width: "20px"}}>
        <svg onClick={onclick} id="Component_4_3" data-name="Component 4 – 3" xmlns="http://www.w3.org/2000/svg"
             width="20" height="20"
             viewBox="0 0 16 16">
            <g id="ic24-info" transform="translate(-2 -2)" style={{isolation: "isolate"}}>
                <path id="Path_38" data-name="Path 38"
                      d="M10,2a8,8,0,1,1-8,8A8,8,0,0,1,10,2Zm0,1.6A6.4,6.4,0,1,0,16.4,10,6.4,6.4,0,0,0,10,3.6Zm0,4.8a.8.8,0,0,1,.8.8V14a.8.8,0,1,1-1.6,0V9.2A.8.8,0,0,1,10,8.4Zm0-3.2a.8.8,0,1,1-.8.8A.8.8,0,0,1,10,5.2Z"
                      fill="#2b2b2b" fillRule="evenodd"/>
            </g>
        </svg>
    </span>
)