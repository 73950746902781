import Image from "next/image";
import {FunctionComponent} from "react";
import {CheckIcon} from "../../../atoms/icons/checkIcon";

interface props {
    title: string
    price: string
    portions: string,
    imageSrc: string,
    active: boolean
    pressCard: () => void
    lastItem?: boolean
}

export const FruitBoxCard: FunctionComponent<props> = ({title, price, portions, active, imageSrc, pressCard, lastItem}) => (
        <button onClick={(e) => {
            e.preventDefault();
            pressCard();
        }}
            className={`swiper-slide z-50 h-184 w-160 md:w-256 ${lastItem ? '' : 'mr-4 md:mr-8 '}md:h-288 bg-natural rounded-24 font-bold text-center checked:bg-green relative shadow-md mb-3 cursor-pointer overflow-hidden inline-block${active ? ' border-green border-2' : ''}`}>
            <Image src={imageSrc} alt={title} height={'134px'} width={'256px'}/>
            <h3 className="text-black md:text-2xl">{title}</h3>
            <p className="text-2xl md:text-4xl text-green">{price}</p>
            <p className="text-gray md:mt-3">{portions} Porties</p>
            {title.includes("Fruitbox M") &&
                <span
                    className={"absolute block text-xs top-[20px] right-[-60px] md:top-[40px] pl-2 md:right-[-60px] rotate-[30deg] md:rotate-45 bg-green text-white w-[240px] md:text-base"}>
                Meest gekozen!
            </span>
            }
            {active ? (
                <span className="absolute right-4 top-4 z-30 text-green fill-current h-16 w-16 md:w-32 md:h-32">
                    <CheckIcon/>
                </span>
            ) : ''}
        </button>
)