import {FC} from "react";
import {Field, useField} from "formik";

interface smallCheckBoxProps {
    fieldName: string;
}

export const SmallCheckBox: FC<smallCheckBoxProps> = ({fieldName, children}) => {
    const [field, meta] = useField(fieldName);
    return (
        <>
            <div className="flex items-center mt-4">
                <input
                    className={`form-checkbox rounded border-green border-2 focus:ring-offset-0 w-24 h-24 text-green mr-3 align-top focus:ring-green`}
                    type='checkbox'
                    {...field}
                    checked={field.value}
                    name={fieldName}
                    id={fieldName}
                />
                <label htmlFor={fieldName} className={`text-black font-normal cursor-pointer block text-12 ${meta.touched && meta.error ? 'text-red' : 'text-gray'}`}>
                    {children}
                </label>
            </div>
            {meta.touched && meta.error ? (
                <div className="col-span-5 font-medium text-red text-12 pt-2">
                    {meta.error}
                </div>
            ) : null}
        </>
    )
};