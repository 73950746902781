import {Field} from "formik";
import {FunctionComponent, ReactNode} from "react";
import {useRecoilState} from "recoil";
import {ModalState} from "../../../stores/modalStore";
import {InfoModal} from "../InfoModal";
import {InfoIcon} from "../../atoms/icons/infoIcon";

interface checkBoxProps {
    fieldName: string;
    labelName: string;
    modalComponent?: ReactNode;
}

export const CheckBox: FunctionComponent<checkBoxProps> = ({fieldName, labelName, modalComponent}) => {
    const [modalState, setModalState] = useRecoilState(ModalState);

    return(
    <label className={'text-black font-bold cursor-pointer mb-6 block inline-flex gap-2 items-center'}>
        <Field
            className={'form-checkbox rounded border-green border-2 focus:ring-offset-0 w-24 h-24 text-green mr-3 align-top focus:ring-green'}
            type='checkbox'
            name={fieldName}
        />
        {labelName}{modalComponent !== undefined ? <InfoIcon onclick={() => {
            setModalState({open: true, modalComponent: modalComponent})
        }}/>: ''}
    </label>
)}