import {Field} from "formik";
import {FC} from "react";

interface ZipcodeFieldProps {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    zipcodeMethod: (zipcode: string, number: string) => void;
    zipcode: string;
    number: string;
    errors: boolean
}

export const ZipcodeField: FC<ZipcodeFieldProps> = ({setFieldValue, zipcodeMethod, zipcode, number,errors,children}) => (
    <div className="grid grid-cols-5 gap-8 gap-y-0 mb-6">
        <div className="col-span-3">
            <label htmlFor="zipcode" className={`${errors ? 'text-red' : 'text-black'} text-12 font-bold`}>Postcode</label>
            <Field
                className={`w-full rounded-lg border-2 focus:ring-0 focus:border-green focus:ring-green ${errors ? 'border-red' : 'border-gray-dark'}`}
                name='zipcode'
                id='zipcode'
                placeholder='1234 AB'
                type='text'
                onChange={(e: any) => {
                    setFieldValue('zipcode', e.target.value, true);
                    zipcodeMethod(e.target.value, number);
                }}
            />
        </div>
        <div className="col-span-2">
            <label htmlFor="number" className={`${errors ? 'text-red' : 'text-black'} text-12 font-bold`}>Huisnummer</label>
            <Field
                className={`w-full rounded-lg border-2 focus:ring-0 focus:border-green focus:ring-green ${errors ? 'border-red' : 'border-gray-dark'}`}
                name='number'
                id='number'
                placeholder='1A'
                type='text'
                onChange={(e: any) => {
                    setFieldValue('number', e.target.value, true);
                    zipcodeMethod(zipcode, e.target.value);
                }}
            />
        </div>
        {children}
    </div>
)