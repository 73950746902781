import {FunctionComponent} from "react";
import {OrderStep} from "./orderStep";
import {AddIcon} from "../atoms/icons/addIcon";
import {Line} from "../atoms/line";
import {PersonIcon} from "../atoms/icons/personIcon";
import {PaperIcon} from "../atoms/icons/paperIcon";
import {CreditCardIcon} from "../atoms/icons/creditCardIcon";

interface props {
    step: number
}

export const OrderProcessBar:FunctionComponent<props> = ({step}) => (
    <nav className={'w-9/12 mx-auto mt-8'}>
        <ul className="flex items-center gap-4">
            <OrderStep active={true} name={'Samenstellen'}>
                <AddIcon/>
            </OrderStep>
            <Line active={step > 0}/>
            <OrderStep active={step > 0} name={'Gegevens&nbsp;&&nbsp;bezorging'}>
                <PersonIcon/>
            </OrderStep>
            <Line active={step > 1}/>
            <OrderStep active={step > 1} name={'Overzicht'}>
                <PaperIcon/>
            </OrderStep>
            <Line active={step > 2}/>
            <OrderStep active={step > 2} name={'Checkout'}>
                <CreditCardIcon/>
            </OrderStep>
        </ul>
    </nav>
)