import {FunctionComponent} from "react";

interface ButtonProps {
    type: 'button' | 'submit';
    onclick?: () => void;
    className?: string;
}

export const ButtonTransparent: FunctionComponent<ButtonProps> = ({type, onclick,children, className = ''}) => (
    <button type={type}
            onClick={onclick}
            className={`text-green font-bold border-2 border-green inline-flex items-center gap-2 p-5 rounded-full focus:outline-none mr-3 ${className}`}>
        {children}
    </button>
)