import {array, boolean, object, string} from "yup";
import {Validation} from "../utils/validation";

export const OrderFormValidator = (subscription: boolean, ignoreEmail?: string | undefined): Array<object> => ([
    object().shape({
        giftMessage: string()
            .when("gift", {
                is: true,
                then: string().required('Vul een berichtje voor op het kaartje!')
            })
    }),
    object().shape({
        fullName: string().required('Voer een naam in!')
            .min(3, 'De voornaam en/of achternaam ontbreekt!')
            .max(191, 'De voornaam en achternaam mag maximal uit 191 tekens bestaan!'),
        email: string().email('Voer een geldig email adres in!').required('Voer een email adres in!')
            .test('email-has-nosubscription',
                'Dit email adres is al gekoppeld aan een abonnement. Gebruik een ander email adres of wijzig uw abonnement.',
                async (value, textContext): Promise<boolean> => {
                    if (subscription && (ignoreEmail === undefined || ignoreEmail !== value)) {
                        return await fetch(process.env.NEXT_PUBLIC_API_HOST + 'subscription/email', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                email: value
                            })
                        }).then(res => {
                            if (res.ok) {
                                return res.json().then((data: { hasSubscription: boolean }) => (!data.hasSubscription))
                            }
                            return false;
                        })
                    }
                    return true;
                }),
        phone: string().required('Voer een telefoonnummer in!')
            .matches(Validation.phoneNumber,
                'Voer een geldig telefoonnummer in!'),
        zipcode: string().required('Voer een geldige postcode en/of huisnummer in!')
            .matches(Validation.zipCode, 'Voer een geldige postcode en/of huisnummer in!'),
        validZipcode: boolean().oneOf([true], 'Voer een geldige postcode en/of huisnummer in!'),
        number: string().required('Voer een geldige postcode en/of huisnummer in!')
            .matches(Validation.number, 'Voer een geldige postcode en/of huisnummer in!'),
        street: string(),
        city: string(),
        deliveryMethod: string(),
        newsPaper: boolean(),
        agreement: boolean().oneOf([true], 'Accepteer de algemene voorwaarden om verder te gaan!'),
    }),
    object().shape({
        paymentMethod: string().required(),
        paymentFrequency: string().oneOf(['1', '4'])
    })
]);