import {FunctionComponent, ReactNode, useEffect, useState} from "react";
import {Header} from "../organisms/header";
import {FormSection} from "../molecules/formSection";

interface props {
    step: number;
    children: ReactNode;
    modalComponent?: ReactNode;
    enableModal?: boolean;
    loading?: boolean;
}

export const FormTemplate: FunctionComponent<props> = ({children, step,modalComponent, enableModal = false, loading = false}) => {
    useEffect(() => {
        if (enableModal) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'initial';
        }
    }, [enableModal])

    return (
        <>
            {enableModal ? (
                <div className={'fixed top-0 h-full w-full bg-[#9A9A9A] bg-opacity-[80%] backdrop-blur z-[100] transition duration-700 flex justify-center items-center overflow-auto'}>
                    {modalComponent}
                </div>
            ) : null}
            <div className={'max-w-screen-lg mx-auto'}>
                <Header step={step}/>
                <FormSection loading={loading}>
                    {children}
                </FormSection>
            </div>
        </>
    )
}