import {DecrementIcon} from "../../../atoms/icons/decrementIcon";
import {IncrementIcon} from "../../../atoms/icons/incrementIcon";
import {FunctionComponent} from "react";

interface ValueCounterProps {
    value: number,
    price: number,
    incrementMethod: () => void,
    decrementMethod: () => void
}

export const ValueCounter: FunctionComponent<ValueCounterProps> = ({value, price,decrementMethod, incrementMethod}) =>{
    return(
    <div className="ml-10 mb-6 flex items-center">
        <button type="button" className="focus:outline-none" onClick={decrementMethod}>
            <DecrementIcon/>
        </button>
        <span className="font-bold text-black mx-2 w-8">
            {value.toFixed(1).replace(".", ",")}L
        </span>
        <button type="button" className="focus:outline-none" onClick={incrementMethod}>
            <IncrementIcon/>
        </button>
        <span className="font-bold text-black mx-2">
            {price.toFixed(2).replace(".", ",")}
        </span>
    </div>
)}