import {FunctionComponent} from "react";

interface props {
    className?: string,
    color?: 'gray' | 'black',
    paddingRight?: string
}

export const Paragraph: FunctionComponent<props> = ({children, color = 'gray', className= '', paddingRight = 'pr-24'}) => (
    <p className={`${paddingRight} text-${color} ${className}`}>{children}</p>
)