import {BackendClient} from "../utils/backendClient";
import {
    ShippingMethodsControllerGetShippingMethodsChangeFlowEnum,
    ShippingMethodsControllerGetShippingMethodsEvenEnum,
    ShippingMethodsControllerGetShippingMethodsOrderTypeEnum,
    ShippingMethodsControllerGetShippingMethodsTwoWeeklyEnum,
    ShippingOutputDto
} from "../generated-sources/openapi";

type Hook = () => [
    (
        zipcode: string,
        number: string,
        subscription: boolean,
        deliveryFrequency?: string,
        even?: boolean,
        changeFlow?: boolean
    ) => Promise<ShippingOutputDto>
]

export const useGetShippingMethods: Hook = () => {
    const getShippingMethods = async (zipcode: string, number: string, subscription: boolean, deliveryFrequency: string = '1', even: boolean = false, changeFlow: boolean = false) => {
        return await BackendClient.shippingMethodsControllerGetShippingMethods({
            zipcode: zipcode.replace(/\s+/g, ''),
            number: number,
            orderType: (subscription ? ShippingMethodsControllerGetShippingMethodsOrderTypeEnum.Subscription : ShippingMethodsControllerGetShippingMethodsOrderTypeEnum.Single),
            twoWeekly: deliveryFrequency === '2' ? ShippingMethodsControllerGetShippingMethodsTwoWeeklyEnum.Yes : ShippingMethodsControllerGetShippingMethodsTwoWeeklyEnum.No,
            even: even? ShippingMethodsControllerGetShippingMethodsEvenEnum.Yes : ShippingMethodsControllerGetShippingMethodsEvenEnum.No,
            changeFlow: changeFlow?  ShippingMethodsControllerGetShippingMethodsChangeFlowEnum.Yes : ShippingMethodsControllerGetShippingMethodsChangeFlowEnum.No
        })
    }

    return [getShippingMethods]
}