import {useField} from "formik";
import {FC} from "react";

interface textFieldProps {
    name: string;
    type: 'text' | 'email' | 'tel' | string;
    label: string;
    placeholder: string;
    readonly?: boolean;
}

export const TextField: FC<textFieldProps> = ({label, name, type, placeholder, readonly = false}) => {
    const [field, meta] = useField(name);
    return (
        <div className='mb-6 text-left'>
            <label htmlFor={name}
                   className={`${meta.touched && meta.error ? 'text-red' : 'text-black'} text-12 font-bold`}>
                {label}
            </label>
            <input
                className={`w-full rounded-lg border-2 focus:ring-0 focus:border-green focus:ring-green ${meta.touched && meta.error ? 'border-red' : 'border-gray-dark'}`}
                {...field}
                name={name}
                id={name}
                type={type}
                placeholder={placeholder}
                readOnly={readonly}
            />
            {meta.touched && meta.error ? (
                <div className="col-span-5 font-medium text-red text-12 pt-2">
                    {meta.error}
                </div>
            ) : null}
        </div>
    );
};