import {Heading} from "../atoms/basics/heading";
import {HeaderOptions} from "../../types/headerOptions";
import {FunctionComponent} from "react";

interface OrderFormFooterProps {
    price: string
}

export const OrderFormFooter: FunctionComponent<OrderFormFooterProps> = ({price, children}) => (
    <div className='md:ml-2 mt-10 mr-24 md:mr-0 md:flex justify-between items-center'>
        <div className='mb-2 md:mb-0'>
            <Heading tag={HeaderOptions.h5}>
                Totaal:
            </Heading>
            <p className="font-bold text-green text-24 mb-4">
                {price}
            </p>
        </div>
        <div className="flex md:block">
            {children}
        </div>
    </div>
)