import {FunctionComponent} from "react";

interface props{
    active: boolean,
    name: string
}

export const OrderStep: FunctionComponent<props> = ({children, active, name}) => (
    <li className={`relative fill-current ${active ? 'text-green' : 'text-gray'}`}>
        {children}
        <div className={'absolute top-8 flex left-0 right-0 justify-center invisible md:visible'}>
            <span className={'font-bold'} dangerouslySetInnerHTML={{__html: name}}/>
        </div>
    </li>
)