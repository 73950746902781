import {Modal} from "./modal";
import {Heading} from "../atoms/basics/heading";
import {HeaderOptions} from "../../types/headerOptions";
import {Paragraph} from "../atoms/basics/paragraph";
import {ButtonTransparent} from "../atoms/buttonTransparent";
import {FC, useState} from "react";
import {useRecoilState} from "recoil";
import {ModalState} from "../../stores/modalStore";

interface props {
    shippingDay: string
}

export const ShippingInfoModal: FC<props> = ({shippingDay}) => {
    const [modalState, setModalState] = useRecoilState(ModalState)

    return (
        <Modal closeModal={() => {setModalState({open: false})}}>
            <Heading tag={HeaderOptions.h6} marginTop={'mt-6'}>
                Ophalen {shippingDay.replace("Ophalen ", "")}.
            </Heading>
            <Paragraph className={'text-xl md:m-6 font-medium mb-12'}>
                U kunt de fruitbox met deze bezorgmethode wekelijks of twee wekelijks ophalen aan de hand de van de frequentie die u kiest,
                op <span className={'font-bold'}>{shippingDay.replace("Ophalen ", "")}</span> tussen <span className={'font-bold'}>10:00 en 16:00</span> bij Deventerseweg 3 in Barendrecht.
            </Paragraph>
            <ButtonTransparent type={'button'} className={'mb-3'} onclick={() => {setModalState({open: false})}}>
                Sluiten
            </ButtonTransparent>
        </Modal>
    )
}
