import {FC, useEffect, useRef} from "react";

export const Modal: FC<{ closeModal: () => void }> = ({children, closeModal}) => {
    const modalRef = useRef(null)
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            // @ts-ignore
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef, closeModal]);

    return (
        <div ref={modalRef} className={'bg-white rounded-3xl w-full max-w-2xl p-5 text-left md:text-center min-h-[320px]'}>
            {children}
        </div>
    )
}