import {FieldConfig, useField} from "formik";
import {FC} from "react";

export const TextArea: FC<{ label: string, props: FieldConfig<string>, placeholder?: string }> = ({label, props, placeholder}) => {
    const [field, meta] = useField(props);
    return (
        <>
            <label htmlFor={props.name}
                   className="text-black mb-2 text-12 font-bold block w-full"
            >
                {label}
            </label>
            <textarea className="block form-textarea rounded border-green border-2 focus:ring-0 focus:border-green focus:ring-green w-80 md:w-2/5"
                      id={field.name}
                      placeholder={placeholder !== undefined ? String(placeholder) : ''}
                      {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="col-span-5 font-medium text-red text-12 pt-2">{meta.error}</div>
            ) : null}
        </>
    )
}