import {FieldConfig, useField} from "formik";
import {FC} from "react";

export const NoteField: FC<{ label: string, props: FieldConfig<string>, placeholder?: string }> = ({label, props, placeholder}) => {
    const [field, meta] = useField(props);
    return (
        <div className={'mb-6 text-left'}>
            <label htmlFor={props.name}
                   className="text-black text-12 font-bold block w-full"
            >
                {label}
            </label>
            <textarea className="block form-textarea rounded w-full border-green border-2 focus:ring-0 focus:border-green focus:ring-green border-gray-dark"
                      id={field.name}
                      placeholder={placeholder !== undefined ? String(placeholder) : ''}
                      {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="col-span-5 font-medium text-red text-12 pt-2">{meta.error}</div>
            ) : null}
        </div>
    )
}