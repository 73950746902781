import {Modal} from "./modal";
import {Heading} from "../atoms/basics/heading";
import {HeaderOptions} from "../../types/headerOptions";
import {Paragraph} from "../atoms/basics/paragraph";
import {ButtonTransparent} from "../atoms/buttonTransparent";
import {FC, useState} from "react";
import {useRecoilState} from "recoil";
import {ModalState} from "../../stores/modalStore";

interface props {
    name: string
}

export const ShippingExternalShippingModal: FC<props> = ({name}) => {
    const [modalState, setModalState] = useRecoilState(ModalState)

    return (
        <Modal closeModal={() => {setModalState({open: false})}}>
            <Heading tag={HeaderOptions.h6} marginTop={'mt-6'}>
                {name}
            </Heading>
            <Paragraph className={'text-xl md:m-6 font-medium mb-12'}>
                Wij sturen onze fruitbox via een partner naar u op, u ontvang daarvan een track en tracé code zodat u
                precies weet wanneer u ons pakket kunt ontvangen.
            </Paragraph>
            <ButtonTransparent type={'button'} className={'mb-3'} onclick={() => {setModalState({open: false})}}>
                Sluiten
            </ButtonTransparent>
        </Modal>
    )
}