import {FunctionComponent} from "react";

interface ButtonProps {
    type: 'button' | 'submit';
    onclick?: () => void;
    className?: string;
}

export const Button: FunctionComponent<ButtonProps> = ({type, onclick,children, className}) => (
    <button type={type}
            onClick={onclick}
            className={`bg-green border-green border-2 text-white font-bold inline-flex items-center gap-2 p-5 rounded-full focus:outline-none ml-auto ${className}`}>
        {children}
    </button>
)